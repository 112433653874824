import './App.css';
import React from 'react';
import './App.css'
import {BrowserRouter, Routes, Route, Link} from "react-router-dom";
import Landingpage from "./Components/landingpage";
import Landingpage_en from "./Components/landingpage_en";
import Gallery from "./Components/gallery";

import landingpage from "./Components/landingpage";


function App() {
  return (
      // <div>
      //     <Landingpage />
      // </div>
    <BrowserRouter>
            <Routes>
                <Route path={'/'} element={<Landingpage/>}/>
                <Route path={'/en'} element={<Landingpage_en/>}/>
                <Route path={'/gallery'} element={<Gallery/>}/>
            </Routes>
    </BrowserRouter>
  );
}

export default App;


import React, {useRef} from "react";
// import video_dunes from "../videos/video_dunes.mp4";
import uk_flag from "../media/united-kingdom-flag-icon-32.png"
import {FaBars, FaTimes, FaPhone, FaFacebookSquare, FaViber, FaInstagramSquare, FaEnvelopeSquare} from "react-icons/fa";
import "../Styles/main.css"
import './footer.css'
import './landingpage.css'
import logo_icon from "../media/logo-png.png"
import m1 from "../media/pictures/miscellaneous/m2.jpg"
import t1 from "../media/pictures/terrace/t1.jpg"
import t2 from "../media/pictures/terrace/t3.jpg"
import b1 from "../media/pictures/bedroom/b3.jpg"
import b2 from "../media/pictures/bedroom/b1.jpg"
import b3 from "../media/pictures/bedroom/b11.jpg"
import b4 from "../media/pictures/bedroom/b10.jpg"
import k1 from "../media/pictures/kitchen/k8.jpg"
import k2 from "../media/pictures/kitchen/k5.jpg"
import l1 from "../media/pictures/livingroom/l10.jpg"
import l2 from "../media/pictures/livingroom/l9.jpg"
import l3 from "../media/pictures/livingroom/l1.jpg"
import l4 from "../media/pictures/livingroom/l11.jpg"
import m2 from "../media/pictures/miscellaneous/m3.jpg"
import m3 from "../media/pictures/miscellaneous/m4.jpg"
import m4 from "../media/pictures/miscellaneous/m9.jpg"
import l5 from "../media/pictures/livingroom/l13.jpg"
import bt1 from "../media/pictures/bt/bt3.jpg"
import bt2 from "../media/pictures/bt/bt9.jpg"
import bt3 from "../media/pictures/bt/bt2.jpg"
import bt4 from "../media/pictures/bt/bt7.jpg"

function Landingpage() {
    const navRef = useRef();
    const showNavbar = () => {
        navRef.current.classList.toggle("responsive_nav");
    }
  return (
    <div className={'landingpage'}>
        {/*<video src={video_dunes} autoPlay muted loop class={'video-bg'} />*/}
        {/*<div className={'bg-overlay'}></div>*/}

        <div className={'navbar'}>
        <header>
            <img src={logo_icon} width={70} height={70}/>
            <nav ref={navRef}>
                <a href={"#about_us"}>За нас</a>
                <a href={"/gallery"}>Галерия</a>
                <a href={"#prices"}>Цени</a>
                <a href={"#location"}>Локация</a>
                <a href={"/en"}>EN <img src={uk_flag}/></a>
                <button className={"nav-btn nav-close-btn"} onClick={showNavbar}>
                    <FaTimes/>
                </button>
            </nav>
            <button className={"nav-btn"} onClick={showNavbar}>
                <FaBars/>
            </button>
        </header>
        </div>

        <div className={'front_image'}>
        </div>

        <div id={"about_us"} className={'slideshow'}>
            <ul className={'slideshow-content'}>
                {/*<li><img src={m1}/></li>*/}
                <li><img src={t1}/></li>
                <li><img src={t2}/></li>
                <li><img src={b1}/></li>
                <li><img src={b2}/></li>
                {/*<li><img src={b3}/></li>*/}
                {/*<li><img src={b4}/></li>*/}
                {/*<li><img src={b5}/></li>*/}
                <li><img src={k1}/></li>
                {/*<li><img src={k2}/></li>*/}
                {/*<li><img src={l1}/></li>*/}
                {/*<li><img src={l2}/></li>*/}
                <li><img src={l3}/></li>
                {/*<li><img src={l4}/></li>*/}
                <li><img src={m2}/></li>
                {/*<li><img src={m3}/></li>*/}
                <li><img src={m4}/></li>
                {/*<li><img src={l5}/></li>*/}
                {/*<li><img src={bt1}/></li>*/}
                <li><img src={bt2}/></li>
                <li><img src={bt3}/></li>
                {/*<li><img src={bt4}/></li>*/}
                <li><img src={t1}/></li>
            </ul>
        </div>

        <div id={'prices'} className={'home-text'}>

            <h1 className={'line'}>За нас</h1>
            <h2>
                Добре дошли в нашия ваканционен апартамент "Несебърски дюни",
                който се намира непосредствено срещу защитените дюни на град Несебър.
                Апартаментът разполага с една спалня, хол с оборудван кухненски бокс и с разтегателен диван.
                В близост има хранителни магазини, заведения за хранене, както и автобусна спирка.
                В районa се паркира свободно.
                При необходимост можем да предоставим детска кошара и столче за хранене.
                Не се допускат домашни любимци. Не се пуши (освен на терасата разбира се :)).
                Апартаментът се намира на 4-ти етаж, сградата е без асансьор.
            </h2>
        </div>

        <div className={'home-prices_bg'}>
            <h1 className={'line'}>Цени</h1>
            <h2>Цената е за нощувка, независимо от броя гости.</h2>
            <h2>Минимумът за резервация е 3 нощувки.</h2>
            <h2>
                <ul>
                    <li>Юни------------120 лв</li>
                    <li>Юли------------150 лв</li>
                    <li>Август---------150 лв</li>
                    <li>Септември----120 лв</li>
                </ul>
            </h2>
            <h2>*При 6 и повече нощувки получавате 10% отстъпка!</h2>
            <h2>*За цени извън посочените месеци се свържете с нас!</h2>
            <h2>Настаняването е след 14:00, освобождаването е до 11:00.</h2>
            <h2>Посочените часове подлежат на коментар след предварителна уговорка.</h2>
        </div>


        <div id={'location'} className={'home-google_bg'}>
            <h1 className={'line'}>Локация</h1>
            <div className={'google-maps'}>
                <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2934.3257594008637!2d27.7037105756021!3d42.654451716452776!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40a69fe9983dd3ed%3A0x85cc92d6eba0e503!2sNessebar%20Dunes%20Apartment!5e0!3m2!1sen!2sbg!4v1718428355817!5m2!1sen!2sbg"
                width="350%"
                height="200"
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade">
                </iframe>
            </div>

        </div>

        <button id={'call_button'} className={'call-buton'}>
            <a className={'cc-calto-action-ripple'} href="tel:+359888424341">
                <i class="fa fa-phone"  aria-hidden="true">
                    <FaPhone/>
                </i>
            </a>
        </button>

        <div className={'contacts'}>
            <div className={'social-icons'}>
                <a href={"https://www.facebook.com/profile.php?id=61563351277601"}>
                    <i>
                        <FaFacebookSquare/>
                    </i>
                </a>
                <a href={"viber://chat?number=359888424341"}>
                    <i>
                        <FaViber/>
                    </i>
                </a>
                <a href={"https://www.instagram.com/nessebardunesnesebrski/"}>
                    <i>
                        <FaInstagramSquare/>
                    </i>
                </a>
                <a href={"mailto:contact@nessebardunes.com"}>
                    <i>
                        <FaEnvelopeSquare/>
                    </i>
                </a>
            </div>
        </div>

        <footer className={'footer_copyright'}>
            <div>
                <p>
                    @{new Date().getFullYear()} Nessebar Dunes. Всички права запазени.
                </p>
            </div>

        </footer>

    </div>
  )
}

export default Landingpage